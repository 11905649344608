/* eslint-disable */
import { useEffect, useState } from "react";
import Logo from "./Logo";
import Up from "./Up";
import Down from "./Down";
import Modal from "./Modal";
import axios from "axios";
import { useTranslation } from "react-i18next";

import i18next from "i18next";


function awaitPushState(handler) {
  //modern themes use pushstate to track variant changes without reload
  function track(fn, handler, before) {
    return function interceptor() {
      if (before) {
        handler.apply(this, arguments);
        return fn.apply(this, arguments);
      } else {
        var result = fn.apply(this, arguments);
        handler.apply(this, arguments);
        return result;
      }
    };
  }

  var currentVariantId = null;
  function variantHandler() {
    var selectedVariantId = window.location.search.replace(
      /.*variant=(\d+).*/,
      "$1"
    );
    if (!selectedVariantId) return;
    if (selectedVariantId != currentVariantId) {
      currentVariantId = selectedVariantId;
      handler(selectedVariantId);
    }
  }

  // Assign listeners
  window.history.pushState = track(history.pushState, variantHandler);
  window.history.replaceState = track(history.replaceState, variantHandler);
  window.addEventListener("popstate", variantHandler);
}

export default function Base({ dataset }) {
  const [show, setShow] = useState(false);
  const [didAdd, setDidAdd] = useState(false);
  const [variant, setVariant] = useState(null);
  const [product, setProduct] = useState(null);
  const [didConsent, setDidConsent] = useState(true);
  // ok
  const [resellifyObject, setResellifyObject] = useState(
    localStorage.getItem("resellifyObject")
      ? JSON.parse(localStorage.getItem("resellifyObject"))
      : {}
  );

  const { t } = useTranslation();
  

  useEffect(() => {

    const _locale = window.Shopify?.locale || 'it';

    i18next.changeLanguage(_locale);


    awaitPushState(function (variantId) {
      if (variantId) {
        setVariant(variantId);
        setDidAdd(false);
      }
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("didConsent", didConsent);
  }, [didConsent]);

  // prod
  useEffect(() => {
    if (resellifyObject[product] === variant) {
      setDidAdd(true);
    }
  }, [product]);

  useEffect(() => {
    if (dataset.variant) {
      setVariant(dataset.variant);
    } else {
      // retrieve from url by variant query param
      const urlParams = new URLSearchParams(window.location.search);
      const _variant = urlParams.get("variant");
      setVariant(_variant);
    }

    if (dataset.product) {
      setProduct(dataset.product);
    }
  }, [dataset]);

  useEffect(() => {
    localStorage.setItem("resellifyObject", JSON.stringify(resellifyObject));
  }, [resellifyObject]);

  const resellifyLogEvent = async (payload) => {
    console.log("dataset", dataset);
    const _url = `https://v1.friendboost.app/integrations/${dataset?.resellify}/resellify/log`;
    const res = await axios.post(_url, payload);
    return res.data;
  };

  useEffect(() => {
    if (didAdd) {
      localStorage.setItem("addedToResellify", true);
      const _ro = { ...resellifyObject };
      if (product) {
        _ro[product] = variant;

        resellifyLogEvent({
          action: "didAdd",
          productId: product,
          variantId: variant,
        });

        setResellifyObject(_ro);
      }
    } else {
      localStorage.removeItem("addedToResellify");
      const _ro = { ...resellifyObject };
      if (product) {
        delete _ro[product];

        resellifyLogEvent({
          action: "didRemove",
          productId: product,
          variantId: variant,
        });

        setResellifyObject(_ro);
      }
    }
  }, [didAdd]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Modal />
      <div className="header-container">
        <div className="logo-container">
          <div className="switch-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={didAdd}
                disabled={!didConsent}
                onChange={(e) => {
                  setDidAdd(e.target.checked);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {/* <p style={{ marginBottom: 0 }}>
            {!didAdd ? "Aggiungi" : "Aggiunto"}</p> */}
          <Logo />

          <button
            className={"toggle-button " + ("toggle-up")}
            onClick={(e) => {
              e.preventDefault();
              setShow((prev) => !prev);
              document
              .getElementById("r-modal-how-to")
              .classList.add("r-modal--visible");
            }}
          >
            <Up />
          </button>
        </div>
        <div
          className={"subtitle"}
          style={{
            marginBottom: 0,
          }}
        >
          <small>
          {/* <strong><span className="claim">Buy Now, Resell later{" "}
              
              {" "}
              
              {parseInt(
                dataset.price / 100 - (dataset.price / 100) * 0.55
              )}€ *
              </span>
            </strong> */}
            <strong>
              <span className="claim">
                {t('addTo')}
              </span>
            </strong>
          </small>
        </div>
      </div>

      {show && (
        <div
          style={{
            marginTop: "10px"
          }}
        >
          <p>
            <strong>{t('widget.title')}</strong>
            <br />
            {t('widget.description')}
            {" "}
            <button
              className="r-button-link"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("r-modal-how-to")
                  .classList.add("r-modal--visible");
              }}
            >
              {t('widget.cta')}
            </button>
          </p>
          <p>
            {t('widget.disclaimer')}
            {" "}
            <a href="https://app.resellify.it/privacy-policy" target="blank">
              Privacy Policy
            </a>
          </p>
          {/* checkbox terms and conditions */}
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              id="terms"
              name="terms"
              value="terms"
              checked={didConsent}
              onChange={(e) => {
                setDidConsent(e.target.checked);
              }}
            />
            <label>{
              t('widget.terms')
              } </label>
          </div>
        </div>
      )}
    </div>
  );
}
