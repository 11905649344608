import { useEffect } from "react";
import Logo from "./Logo";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Modal() {
  const { t } = useTranslation();

  useEffect(() => {
    const _locale = window.Shopify?.locale || "it";

    i18next.changeLanguage(_locale);
  }, []);

  return (
    <div id="r-modal-how-to" class="r-modal">
      <div class="r-modal__content">
        <div className="r-modal-header">
          <Logo />
          <p style={{
            marginTop: "10px"
          }}>in collaboration with T-Luxy</p>
        </div>

        <div className="r-box pb-5 text-center bg-third">
          <h3
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: t("modalV2.title"),
            }}
          ></h3>
          <p
            style={{
              textAlign: "center",
              width: "100%",
              display: "block",
            }}
          >
            {t("modalV2.subtitle")}
          </p>

          <h6>{t("modalV2.howitworks")}</h6>

          <div className="rounded-box pb-5">
            <p
              style={{
                textAlign: "center",
                width: "100%",
                display: "block",
                fontWeight: 300,
                fontSize: "1.1em",
                marginTop: "0px",
              }}
            >
              {t("modalV2.claim")}
            </p>

            <div
              className="thesteps"
            >
              <div>
                <img
                  src="https://res.cloudinary.com/deakxmqqh/image/upload/c_pad,w_400/v1710437228/ICONE_1_evpz75.png"
                  className="max-w"
                  style={{ transform: "scale(.8)" }}
                />
                <div>
                  <p style={{ fontWeight: 500 }}>
                    <span className="wdot">1</span> {t("modalV2.step1")}
                  </p>
                  <p>{t("modalV2.step1desc")}</p>
                </div>
              </div>

              <div>
                <img
                  src="https://res.cloudinary.com/deakxmqqh/image/upload/c_pad,w_400/v1710437229/ICONE-03_rgg8cl.png"
                  className="max-w"
                  style={{ transform: "scale(.8)" }}
                />
                <p style={{ fontWeight: 500 }}>
                  {" "}
                  <span className="wdot">2</span> {t("modalV2.step2")}
                </p>
                <p>{t("modalV2.step2desc")}</p>
              </div>

              <div>
                <img
                  src="https://res.cloudinary.com/deakxmqqh/image/upload/c_pad,w_400/v1710437229/ICONE-02_payu66.png"
                  className="max-w"
                  style={{ transform: "scale(.6)" }}
                />
                <p style={{ fontWeight: 500 }}>
                  {" "}
                  <span className="wdot">3</span> {t("modalV2.step3")}
                </p>
                <p>{t("modalV2.step3desc")}</p>
              </div>
            </div>
          </div>

          <div
            className="rounded-box"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div>
              <img 
                style={{
                  position: "relative",
                  top: "5px",
                }}
              src="https://res.cloudinary.com/deakxmqqh/image/upload/v1710437897/Screenshot_2024-03-14_alle_18.38.10_dqwwql.png" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  marginTop: "10px",
                }}
              >
                {t("modalV2.whatif")}
              </h3>
              <p
                style={{
                  textAlign: "left",
                }}
              >
                {t("modalV2.whatifdesc")}
              </p>
            </div>
          </div>
        </div>

        <a
          href="javascript:void(0)"
          onClick={() => {
            document
              .getElementById("r-modal-how-to")
              .classList.remove("r-modal--visible");
          }}
          class="r-modal__close"
        >
          &times;
        </a>
      </div>
    </div>
  );
}
