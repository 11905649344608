import React from "react";

function Down() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M8 10.766l5.113-5.6a.518.518 0 01.716-.042c.21.18.23.49.043.694L8.38 11.834a.515.515 0 01-.76 0L2.128 5.818a.482.482 0 01.043-.694c.21-.18.53-.162.716.042L8 10.766z"></path>
    </svg>
  );
}

export default Down;
