/* eslint-disable */
import { useEffect, useState } from "react";
import Logo from "./Logo";
import Up from "./Up";
import Down from "./Down";
import Modal from "./Modal";
import axios from "axios";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Order({ dataset }) {
  const [show, setShow] = useState(false);
  const [didAdd, setDidAdd] = useState(false);
  const [variant, setVariant] = useState(null);
  const [product, setProduct] = useState(null);
  const [didConsent, setDidConsent] = useState(true);
  const { t } = useTranslation();
  const [resellifyObject, setResellifyObject] = useState(
    localStorage.getItem("resellifyObject")
      ? JSON.parse(localStorage.getItem("resellifyObject"))
      : {}
  );

  useEffect(() => {
    localStorage.setItem("didConsent", didConsent);
  }, [didConsent]);

  useEffect(() => {
    const _locale = window.Shopify?.locale || "it";

    i18next.changeLanguage(_locale);
  }, []);

  useEffect(() => {
    localStorage.setItem("resellifyObject", JSON.stringify(resellifyObject));
  }, [resellifyObject]);

  const processOrder = async () => {
    const _url = `https://v1.friendboost.app/integrations/${dataset?.resellify}/resellify/products/send`;
    const payload = {
      item: {
        product_ids: Object.keys(resellifyObject),
        variant_ids: Object.values(resellifyObject),
      },
      customer: {
        email: window.Shopify?.checkout?.email,
      },
    };
    const res = await axios.post(_url, payload);
    localStorage.removeItem("resellifyObject");
  };

  useEffect(() => {
    if (resellifyObject) {
      if (Object.keys(resellifyObject).length > 0) {
        setDidAdd(true);
        // confermato
        console.log("si");
        processOrder();
      }
    }
  }, [resellifyObject]);

  if (!didAdd) {
    return <></>;
  }

  return (
    <div>
      <Modal />
      <div className="header-container">
        <div className="logo-container">
          <div className="switch-container">
            <label className="switch">
              <input type="checkbox" checked={didAdd} disabled={true} />
              <span className="slider round"></span>
            </label>
          </div>
          <Logo />
        </div>
      </div>
    </div>
  );
}
