/* eslint-disable */
import { useEffect, useState } from "react";
import Logo from "./Logo";
import Up from "./Up";
import Down from "./Down";
import Modal from "./Modal";
import axios from "axios";

export default function Cart({ dataset }) {
  const [show, setShow] = useState(true);
  const [didAdd, setDidAdd] = useState(false);
  const [variant, setVariant] = useState(null);
  const [product, setProduct] = useState(null);
  const [resellifyObject, setResellifyObject] = useState(
    localStorage.getItem("resellifyObject")
      ? JSON.parse(localStorage.getItem("resellifyObject"))
      : {}
  );
  const [didConsent, setDidConsent] = useState(
    true
  );

  const resellifyLogEvent = async (payload) => {
    console.log('dataset', dataset)
    const _url = `https://v1.friendboost.app/integrations/${dataset?.resellify}/resellify/log`;
    const res = await axios.post(_url, payload);
    return res.data;
  };


  useEffect(() => {
    localStorage.setItem("didConsent", didConsent);
  }, [didConsent]);

  useEffect(() => {
    if (resellifyObject[product] === variant) {
      setDidAdd(true);
    }
  }, [variant]);

  useEffect(() => {
    if (dataset.variant) {
      setVariant(dataset.variant);
    } else {
      // retrieve from url by variant query param
      const urlParams = new URLSearchParams(window.location.search);
      const _variant = urlParams.get("variant");
      setVariant(_variant);
    }

    if (dataset.product) {
      setProduct(dataset.product);
    }
  }, [dataset]);

  useEffect(() => {
    // ok
    localStorage.setItem("resellifyObject", JSON.stringify(resellifyObject));
  }, [resellifyObject]);

  

  useEffect(() => {
    if (didAdd) {
      localStorage.setItem("addedToResellify", true);
      const _ro = { ...resellifyObject };
      if (product) {
        _ro[product] = variant;
        setResellifyObject(_ro);

        resellifyLogEvent({
          action: "didAdd",
          productId: product,
          variantId: variant,
        });

      }
    } else {
      // fix
      localStorage.removeItem("addedToResellify");
      const _ro = { ...resellifyObject };
      if (product) {
        delete _ro[product];
        setResellifyObject(_ro);

        resellifyLogEvent({
          action: "didRemove",
          productId: product,
          variantId: variant,
        });
      }
    }
  }, [didAdd]);

  return (
    <div>
      <Modal />
      <div className="header-container">
        <div className="logo-container">
          <div className="switch-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={didAdd}
                disabled={!didConsent}
                onChange={(e) => {
                  setDidAdd(e.target.checked);
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {/* <p style={{marginBottom: 0}}>{!didAdd ? "Aggiungi" : "Aggiunto"}</p> */}
          <Logo />
        </div>
      </div>
    </div>
  );
}
