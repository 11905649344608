import { useEffect } from "react";
import Base from "./components/Base";
import Cart from "./components/Cart";
import Order from "./components/Order";

function App({ dataset }) {
  useEffect(() => {
    console.log(dataset, "v4");
  }, [dataset]);
  return <>
    {dataset?.template === 'base' && <Base dataset={dataset} />}
    {dataset?.template === 'cart' && <Cart dataset={dataset} />}
    {dataset?.template === 'order' && <Order dataset={dataset} />}
  </>;
}

export default App;
